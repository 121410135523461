const pageScroll = () => {

    $(document).on('click','a.page-scroll',function(event){
        event.preventDefault();
        var $anchor=$(this);
        $('html,body').animate({
        // scrollTop: ($($anchor.attr('href')).offset().top-57) // 58 : height of navbar
        scrollTop: ($($anchor.attr('href')).offset().top)
        },1500);
    });

}
export default pageScroll;