const changeActiveCarouselPicture = () => {

    const clickPicture = (clickedPicture) => {
        const id = clickedPicture.dataset.realisationid;

        let carouselRealisationItems = document.querySelectorAll('.carousel-item-realisation');
        if (carouselRealisationItems){
            carouselRealisationItems.forEach((carouselRealisationItem) => {
                carouselRealisationItem.classList.remove("active");
                if (carouselRealisationItem.dataset.realisationid == id) {
                    carouselRealisationItem.classList.add("active");
                    console.log('find active picture')
                }
            });
        }            
    }

    let pictures = document.querySelectorAll('.realisations-grid-picture');
    if (pictures){
        pictures.forEach((picture) => {
            picture.addEventListener('click', (event) => {
            clickPicture(picture);
        });
        });
    }
    
}
export default changeActiveCarouselPicture;