const backToTop = () => {

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function() {scrollFunction()};
    
    function scrollFunction() {
        const backToTopEl = document.getElementById("backToTopBtn");
        if (backToTopEl){		
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                backToTopEl.style.display = "block";
                backToTopEl.addEventListener('click', (event) => {backToTopFunction();});
            } else {
                backToTopEl.style.display = "none";
            }
        }
    }
    
    // When the user clicks on the button, scroll to the top of the document
    
    function backToTopFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
        
}
export default backToTop;